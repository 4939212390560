
import {motion} from 'framer-motion'
const GMR = () => {
    return (
        
    <div id="GMR">
    <motion.h3  initial={{y:-50, opacity:0}}
          animate={{y:0, opacity:1}}
          transition={{duration:0.5}} className="flex gap-1 font-medium text-xl font-titleFont text-lightestslate"> Software Development Engineer Intern <span className="text-green tracking-wide">@GMR</span></motion.h3>
    <motion.p  initial={{y:-50, opacity:0}}
          animate={{y:0, opacity:1}}
          transition={{duration:0.7}} className="mt-1 mb-1">
        Mar 2017 - Jun 2017
    </motion.p>

    <ul className="max-w-[70%]" >
    <motion.li  initial={{y:-50, opacity:0}}
          animate={{y:0, opacity:1}}
          transition={{duration:0.9}} className="flex gap-2 pt-2">
            <i className ="fa fa-arrow-right text-green pt-2"></i>
            <span> Developed, maintained and shipped production code for client websites using HTML, CSS, JavaScript and jQuery </span>
        </motion.li>
        <motion.li initial={{y:-50, opacity:0}}
          animate={{y:0, opacity:1}}
          transition={{duration:1.1}}  className="flex gap-2 pt-2">
            <i className =" fa fa-arrow-right text-green pt-2"></i>
            <span className=''> Implemented responsive design principles, ensuring seamless user interactions across various devices and screen sizes, optimizing accessibility and usability </span>
        </motion.li>

        <motion.li initial={{y:-50, opacity:0}}
          animate={{y:0, opacity:1}}
          transition={{duration:1.3}} className="flex gap-2 pt-2">
            <i className =" fa fa-arrow-right text-green pt-2"></i>
            <span className=''>  Implemented and configured batch jobs for automated and scheduled updates of database entries, ensuring data consistency and accuracy  </span>
        </motion.li>
    
        

    </ul>

</div>
    )
}

export default GMR;