import {motion} from 'framer-motion';
const vz = ()=>{
    return (
        <div id="Verizon" >
            <motion.h3 initial={{y:-50, opacity:0}}
          animate={{y:0, opacity:1}}
          transition={{duration:0.5}} className="flex  flex-wrap gap-1 font-medium text-xl font-titleFont text-lightestslate"> Software Development Engineer <span className="text-green tracking-wide">@OPEL Systems</span></motion.h3>
            <motion.p  initial={{y:-50, opacity:0}}
          animate={{y:0, opacity:1}}
          transition={{duration:0.6}}className="mt-1 mb-1">
                Apr 2023 - Dec 2023
            </motion.p>

            <ul className="max-w-[70%]" >
            <motion.li  initial={{y:-50, opacity:0}}
          animate={{y:0, opacity:1}}
          transition={{duration:0.7}}  className="flex gap-2 pt-2">
                    <i className ="fa fa-arrow-right text-green pt-2"></i>
                    <span> Engineered the migration of a monolith to microservices using the Strangler pattern </span>
                </motion.li>
                <motion.li initial={{y:-50, opacity:0}}
          animate={{y:0, opacity:1}}
          transition={{duration:0.9}}  className="flex gap-2 pt-2">
                    <i className =" fa fa-arrow-right text-green pt-2"></i>
                    <span className=''> Developed custom hooks to encapsulate complex logic and handle API calls</span>
                </motion.li>

                <motion.li initial={{y:-50, opacity:0}}
          animate={{y:0, opacity:1}}
          transition={{duration:1.1}}  className="flex gap-2 pt-2">
                    <i className =" fa fa-arrow-right text-green pt-2"></i>
                    <span className=''>  Developed a library of Reusable components like Shimmer UI and Nav bars, used Redux for state management and React router for route management </span>
                </motion.li>
               

                <motion.li  initial={{y:-50, opacity:0}}
          animate={{y:0, opacity:1}}
          transition={{duration:1.3}}  className="flex gap-2 pt-2">
                    <i className =" fa fa-arrow-right text-green pt-2"></i>
                    <span className=''> Streamlined software delivery by constructing end-to-end CI/CD workflows, ensuring seamless integration and automated deployment </span>
                </motion.li>


                

            </ul>

        </div>
    )
}
export default vz;