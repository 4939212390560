import React from "react";
import {motion} from 'framer-motion'
const Infosys =()=> {
    return (
      
    <div id="Infosys">
    <motion.h3  initial={{y:-50, opacity:0}}
          animate={{y:0, opacity:1}}
          transition={{duration:0.5}} className="flex gap-1 font-medium text-xl font-titleFont text-lightestslate"> Software Development Engineer <span className="text-green tracking-wide">@Infosys</span></motion.h3>
    <motion.p  initial={{y:-50, opacity:0}}
          animate={{y:0, opacity:1}}
          transition={{duration:0.6}} className="mt-1 mb-1">
        Aug 2018 - Oct 2021
    </motion.p>

    <ul className="max-w-[70%]" >
    <motion.li  initial={{y:-50, opacity:0}}
          animate={{y:0, opacity:1}}
          transition={{duration:0.7}} className="flex gap-4 pt-2">
            <i className ="fa fa-arrow-right text-green pt-2"></i>
            <span>Designed and implemented a web application utilizing Spring MVC, tailored to meet the unique needs of clients within the Financial and Industrial sectors </span>
        </motion.li>
        <motion.li  initial={{y:-50, opacity:0}}
          animate={{y:0, opacity:1}}
          transition={{duration:0.9}} className="flex gap-4 pt-2">
            <i className =" fa fa-arrow-right text-green pt-2"></i>
            <span className=''> Worked closely with designers to convert the wireframes into working prototypes for a customer-facing web application </span>
        </motion.li>

        <motion.li initial={{y:-50, opacity:0}}
          animate={{y:0, opacity:1}}
          transition={{duration:1.1}} className="flex gap-4 pt-2">
            <i className =" fa fa-arrow-right text-green pt-2"></i>
            <span className=''> Played a key role in enhancing Compass, a React-powered platform designed to facilitate employee benefits and collaborative work-sharing, particularly impactful during the COVID-19 pandemic </span>
        </motion.li>
       

        <motion.li initial={{y:-50, opacity:0}}
          animate={{y:0, opacity:1}}
          transition={{duration:1.3}} className="flex gap-4 pt-2">
            <i className =" fa fa-arrow-right text-green pt-2"></i>
            <span className=''> Orchestrated and maintained AWS infrastructure, implementing secure access controls through IAM roles and policies </span>
        </motion.li>


        

    </ul>

</div>
    )
    }

    export default Infosys;