import React from "react";
import {motion} from 'framer-motion'
const ThinkMax =()=> {
return (

    <div id="thinkmax">
    <motion.h3  initial={{y:-50, opacity:0}}
          animate={{y:0, opacity:1}}
          transition={{duration:0.5}}className="flex gap-1 font-medium text-xl font-titleFont text-lightestslate"> Software Development Engineer Intern <span className="text-green tracking-wide">@ThinkMax</span></motion.h3>
    <motion.p  initial={{y:-50, opacity:0}}
          animate={{y:0, opacity:1}}
          transition={{duration:0.7}} className="mt-1 mb-1">
        Apr 2018 - July 2018
    </motion.p>

    <ul className="max-w-[70%]" >
    <motion.li  initial={{y:-50, opacity:0}}
          animate={{y:0, opacity:1}}
          transition={{duration:0.9}} className="flex gap-2 pt-2">
            <i className ="fa fa-arrow-right text-green pt-2"></i>
            <span> Engineered a user-centric web application, utilizing React and SCSS, to meet the specific requirements of a client within the e-commerce domain</span>
        </motion.li>
        <motion.li  initial={{y:-50, opacity:0}}
          animate={{y:0, opacity:1}}
          transition={{duration:1.1}} className="flex gap-2 pt-2">
            <i className =" fa fa-arrow-right text-green pt-2"></i>
            <span className=''> Implemented Sequelize ORM to initialize the database, enhancing data security through secure write operations</span>
        </motion.li>

        <motion.li initial={{y:-50, opacity:0}}
          animate={{y:0, opacity:1}}
          transition={{duration:1.3}} className="flex gap-2 pt-2">
            <i className =" fa fa-arrow-right text-green pt-2"></i>
            <span className=''> Crafted middleware functions for an Express.js backend, ensuring the seamless execution of business logic</span>
        </motion.li>
       

        


        

    </ul>

</div>
)
}

export default ThinkMax;