import LeftSide from "./LeftSide";
import Rightside from "./Rightside";
import Vz from "./Vz";
import { useState } from "react";
import Infosys from "./Infosys"
import ThinkMax from "./ThinkMax";
import GMR from "./GMR";
import {motion} from "framer-motion"
const Exp = ()=> {

    const [exp, setExp] = useState("Verizon");
    return (
        <div  id="Experience" className="max-w-contianerxs mx-auto px-[10%] w-full pb-[10%]">
             < motion.h1 initial={{y:-10, opacity:0}}
          animate={{y:0, opacity:1}}
          transition={{duration:0.5}}  className="text-lightestslate mt-8 sm:text-lg md:text-2xl lg:text-3xl xl:text-3xl font-bold"> Where I've Worked </motion.h1>

        <div className="text-slate mt-10 flex flex-row gap-16">
           
            <ul className="max-w-[16%]">
                <motion.li  initial={{y:-10, opacity:0}}
          animate={{y:0, opacity:1}}
          transition={{duration:0.7}} className={`${
    exp === 'Verizon'
      ? 'text-green  border-l-2 border-l-green'
      : 'text-slate border-l-2 border-l-slate' // Changed this part
  } bg-transparent hover:bg-[#112240] py-3 text-sm cursor-pointer duration-300 px-8`} 
                onClick={()=>{setExp("Verizon")}}
                >
                    OPEL Systems</motion.li>
                <motion.li  initial={{y:-10, opacity:0}}
          animate={{y:0, opacity:1}}
          transition={{duration:0.9}}  className={`${
    exp === 'Infosys'
      ? 'text-green  border-l-2 border-l-green'
      : 'text-slate border-l-2 border-l-slate' // Changed this part
  } bg-transparent hover:bg-[#112240] py-3 text-sm cursor-pointer duration-300 px-8`}  onClick={()=>{setExp("Infosys")}}>Infosys</motion.li>
            
        <motion.li initial={{y:-10, opacity:0}}
          animate={{y:0, opacity:1}}
          transition={{duration:1.1}}  className={`${
    exp === 'ThinkMax'
      ? 'text-green  border-l-2 border-l-green'
      : 'text-slate border-l-2 border-l-slate' // Changed this part
  } bg-transparent hover:bg-[#112240] py-3 text-sm cursor-pointer duration-300 px-8`}  onClick={()=>{setExp("ThinkMax")}}>ThinkMax</motion.li>
               
               
                <motion.li  initial={{y:-10, opacity:0}}
          animate={{y:0, opacity:1}}
          transition={{duration:1.3}}  className={`${
    exp === 'GMR'
      ? 'text-green  border-l-2 border-l-green'
      : 'text-slate border-l-2 border-l-slate' // Changed this part
  } bg-transparent hover:bg-[#112240] py-3 text-sm cursor-pointer duration-300 px-8`}  onClick={()=>{setExp("GMR")}}>GMR</motion.li>
            </ul>
            {exp==="Verizon" && <Vz/>}
            {exp==="Infosys" && <Infosys/>}
            {exp==="ThinkMax" && <ThinkMax/>}
            {exp==="GMR" && <GMR/>}

            

        </div>






 <motion.div initial={{y:-50, opacity:0}}
          animate={{y:0, opacity:1}}
          transition={{duration:0.3}} className="hidden xl:inline-flex w-32 h-full fixed right-0 bottom-[0%]">
        <Rightside/>
    </motion.div>

    <motion.div initial={{y:-50, opacity:0}}
          animate={{y:0, opacity:1}}
          transition={{duration:0.1}} className="hidden xl:inline-flex w-32 h-full fixed left-0 bottom-[0%]">
        <LeftSide/>
    </motion.div>


        </div>
    )
}

export default Exp;